<template>
  <v-container class="mt-10 mb-10">
    <v-row class="mx-2">
      <v-col
        md="12"
        class="text-h3 text-center mb-4"
        :style="{ color: '#0c4f88' }"
      >
        Informasi
      </v-col>
      <v-col md="6">
        <v-card :loading="loading.data" outlined>
          <v-row no-gutters>
            <v-col cols="12" md="5" class="pa-0">
              <v-img
                height="250"
                :src="require('@/assets/different.jpg')"
              ></v-img>
            </v-col>
            <v-col cols="12" md="7" class="pa-0">
              <v-card-title class="text-h5"
                >Perbedaan Penjaminan dengan Asuransi ?
              </v-card-title>
              <v-card-text>
                <v-row align="center" class="mx-0 mb-3">
                  <v-rating
                    :value="9"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>
                </v-row>
                <div>
                  Perbedaan Penjaminan dengan Asuransi adalah Penjaminan
                  merupakan kegiatan pemberian...
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="NewsDetail">
                  Detail
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card :loading="loading.data" outlined style="min-height: 250px">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="5"
              class="d-flex justify-center align-center pr-2 pl-2"
              :style="{ height: '250px' }"
            >
              <v-img
                width="100%"
                :src="require('@/assets/asippindo.png')"
                class=""
              ></v-img>
            </v-col>
            <v-col cols="12" md="7" class="pa-0">
              <v-card-title class="text-h5">ASIPPINDO </v-card-title>
              <v-card-text>
                <v-row align="center" class="mx-0 mb-3">
                  <v-rating
                    :value="5"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>
                </v-row>
                <div>
                  PT. Orion Penjaminan Indonesia telah tergabung dalam Asosiasi
                  Perusahaan Penjaminan Indonesia (ASIPPINDO)...
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="reserve">
                  Detail
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.detail"
      width="900px"
      persistent
      style="border-radius: 20px"
    >
      <v-card>
        <v-card-actions style="height: 80px">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            outlined
            color="grey"
            dark
            @click="dialog.detail = false"
            class="mr-5"
          >
            X
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-progress-linear
          indeterminate
          v-if="loading.detail"
        ></v-progress-linear>
        <v-container>
          <v-row class="mx-2">
            <v-col
              cols="12"
              class="text-h4 text-center"
              style="align-self: center; color: #114e87"
            >
              {{ detail.name }}
            </v-col>
            <v-col cols="12" class="d-flex justify-center mb-4">
              <v-row>
                <!-- <v-col cols="12" sm="4" md="3" class="text-center">
                  <img
                    :src="
                      detail.img
                        ? require(`@/assets/products/${detail.img}`)
                        : require('@/assets/user-no-photo.png')
                    "
                    :alt="detail.img ? detail.img : 'Placeholder Image'"
                    :style="{ border: '1px solid #ccc' }"
                    height="250"
                    width="250"
                  /> -->
                <!-- </v-col> -->
                <v-col cols="12" class="d-flex align-center">
                  <div class="text-justify" v-html="detail.description"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "SectionNewsletterAlt",
  data: () => ({
    dialog: {
      detail: false,
    },
    loading: {
      data: false,
      detail: false,
    },
    detail: {
      name: "",
      img: "",
      description: "",
    },
    selection: 1,
  }),

  methods: {
    // NewsDetail(thumbnail, title, description) {
    //   this.dialog.detail = true;
    // },
  },
};
</script>
